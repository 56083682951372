import React from "react"
// import { Helmet } from "react-helmet"
import { Seo } from "components/seo"
import { DefaultHeader } from "components/headers/default-header"
import { Pagination } from "components/pagination"
import { Footer } from "components/footer"
import { Recommend } from "components/blog/recommend"
import { AccessRanking } from "components/blog/access-ranking"
import { Tags } from "components/blog/tags"
import { Categories } from "components/blog/categories"
import { Events } from "components/blog/events"

const blogs = require("data/blog.json")

const BlogPage = props => {
  return (
    <>
      <DefaultHeader />
      <Seo
        pagetitle="ブログ"
        pagepath={props.location.pathname}
        pagedesc="ブログ一覧 | PARKLoT(パークロット)は「月額制」で「何回でも」実施できるTwitterキャンペーンツールを提供しています。"
      />
      <main className="information">
        <section className="information__heading py-10 px-4 md:pt-14 md:pb-16">
          <div className="text-center">
            <h1 className="text-white font-bold">PARKLoT ブログ</h1>
          </div>
        </section>
        <section className="md:py-16 pb-16 pt-4 l-container">
          <div>
            <ul className="md:flex md:flex-wrap blog-list__container">
              {blogs
                .slice(
                  props.pageContext.skip,
                  props.pageContext.skip + props.pageContext.limit
                )
                .map((blog, index) => {
                  const imageUrl = blog.imageUrl
                  return (
                    <li key={index}>
                      <a className="blog-list__link" href={blog.href}>
                        <img
                          src={imageUrl}
                          layout="constrained"
                          placeholder="tracedSVG"
                          alt={blog.alt}
                        />
                        <div className="mt-4 mb-2">
                          <span className="px-3 py-1 bg-primary text-white rounded text-xs inline-block">
                            {blog.type}
                          </span>
                        </div>
                        <p className="font-bold mb-3">{blog.title}</p>
                        <div className="blog-list__meta">
                          <div className="flex">
                            <p className="text-xs mb-1 font-bold mr-4 empty:hidden">{blog.updateDate}</p>
                            <p className="text-xs mb-1">{blog.releaseDate}</p>
                          </div>
                          <p className="text-xs font-bold">
                            本記事は{blog.minutes}で読むことができます
                          </p>
                        </div>
                      </a>
                    </li>
                  )
                })}
            </ul>
          </div>
          <Pagination totalCount={blogs.length} />
        </section>
        <hr className="md:hidden w-full border-0 border-t-4 border-middlegrey mt-6 mb-8" />
        <section className="md:flex md:gap-14 l-container">
          <div className="md:w-2/3">
            <AccessRanking />
            <Recommend />
          </div>
          <div className="md:w-1/3">
            <Categories />
            <Tags />
            <Events />
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}

export default BlogPage
